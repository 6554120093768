<template>
  <div>
    <div class="content-0to5" v-if="question.type === 'nes'">
      <app-form-rate
        v-model="inputValue"
        :items="returnMyItems"
        v-on:change.native="response(inputValue)"
        item-value="value"
        :isGradient="question.gradient"
        :textColor="colors.actionText"
        :labelColor="colors.splashColor"
        :bgColor="colors.actionColor"
        :hoverColor="colors.buttonHover"
        item-text="id"
        :min-label="question.startLabel"
        :max-label="question.endLabel"
      />
    </div>
    <div class="content-0to10" v-if="question.type !== 'nes'">
      <app-form-rate
        v-model="inputValue"
        v-on:change.native="response(inputValue)"
        :items="returnMyItems"
        item-value="value"
        item-text="id"
        :isGradient="question.gradient"
        :textColor="colors.actionText"
        :labelColor="colors.splashColor"
        :hoverColor="colors.buttonHover"
        :bgColor="colors.actionColor"
        :min-label="question.startLabel"
        :max-label="question.endLabel"
      />
    </div>
  </div>
</template>

<script>
import { gradientMaker } from '@/_helpers'
export default {
  name: 'app10num',
  props: ['colors', 'question'],
  components: {
    'app-form-rate': () => import('@binds-tech/binds-design-system/src/components/Form/Rate')
  },
  data () {
    return {
      inputValue: null,
      items: [
        { id: 0, value: 0 },
        { id: 1, value: 1 },
        { id: 2, value: 2 },
        { id: 3, value: 3 },
        { id: 4, value: 4 },
        { id: 5, value: 5 },
        { id: 6, value: 6 },
        { id: 7, value: 7 },
        { id: 8, value: 8 },
        { id: 9, value: 9 },
        { id: 10, value: 10 }
      ],
      nes: [
        { id: 1, value: 0 },
        { id: 2, value: 25 },
        { id: 3, value: 50 },
        { id: 4, value: 75 },
        { id: 5, value: 100 }
      ]
    }
  },
  mounted () {
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.skipQuestion()
      }
    })
  },
  computed: {
    returnMyItems () {
      let item = [...this.nes]
      if (this.question.type !== 'nes') {
        item = [...this.items]
      }
      gradientMaker.checkGradientAndReturnTheColors(this.question, item)
      return item
    }
  },
  methods: {
    response (value) {
      const body = {}
      if (this.question.type !== 'nes') {
        value = value * 10
      }
      body.rating = value
      if (this.question.type === 'csat' && this.question.options) {
        body.value = value / 10
      }
      this.nextQuestion(body)
    },
    skipQuestion () {
      this.nextQuestion({ skip: true })
    },
    nextQuestion (body) {
      this.$emit('value-changed', body)
      this.$emit('question-answered', true)
    }
  }
}
</script>
<style lang="scss">
.content-0to5, .content-0to10 {
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  .rating__label--min {
    width: 100px;
    word-break: break-word;
    text-align: left;
  }
  .rating__label--max {
    width: 100px;
    word-break: break-word;
    text-align: right;
  }
}

//responsivity to mobile
@media only screen and (max-width: 850px) {
  .content-0to10, .content-0to5 {
    height: 100% !important;
  }
  .content-0to10 {
    .rating {
      .rating__item {
        margin-right: 4px;
        .rating__button {
          width: 30px;
          height: 30px;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .content-0to10 {
    .rating {
      .rating__item {
        margin-right: 3px;
        .rating__button {
          width: 28px !important;
          height: 28px !important;
        }
      }
    }
  }
}
</style>
